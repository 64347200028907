import {useIslamicCenters} from "#imports";
import type {IslamicCentersInstantSearchData} from "~/types";

export const useMainIslamicCentersDataManager = () => {
    const islamicCentersStore = useIslamicCenters();
    const mainIslamicCentersDataService = useMainIslamicCentersDataService();

    const getMainIslamicCenter = async () => {
        return await mainIslamicCentersDataService.fetchMainIslamicCenter();
    }

    const getMainIslamicCenters = async () => {
        const results = await mainIslamicCentersDataService.fetchMainIslamicCentersData();
        return results?.value;
    }

    const getMainIslamicCentersFromUrl = async (url: string) => {
        return await mainIslamicCentersDataService.fetchMainIslamicCentersDataFromUrl(url);
    }
    
    const getIslamicCenterReportTypes = async () => {
        const storedIslamicCenterReportTypes = computed(() => {
            return islamicCentersStore.islamic_center_report_types;
        })
        if (storedIslamicCenterReportTypes.value == null || storedIslamicCenterReportTypes.value.length == 0 || !islamicCentersStore.isIslamicCenterReportTypesValid()) {
            const fundraisingCampaignReportTypes =  await mainIslamicCentersDataService.fetchIslamicCenterReportTypes();
            if(fundraisingCampaignReportTypes)
                islamicCentersStore.setIslamicCenterReportTypes(fundraisingCampaignReportTypes);
        }

        return storedIslamicCenterReportTypes;
    }

    const reportIslamicCenter = async (data:object) => {
        const createdIslamicCenterReport = await mainIslamicCentersDataService.requestToCreateNewIslamicCenterReport(data);
        if (createdIslamicCenterReport) {
            useAlertSetter().setSuccessAlert("Islamic Center Reported Successfully");
            return createdIslamicCenterReport;
        }
        return null;
    }

    //TODO: Islamic Center Subscribe Code Goes Here


    const getIslamicCenterInstantSearchResults = async (query:string) => {
        return await mainIslamicCentersDataService.fetchInstantSearchResults(query) as IslamicCentersInstantSearchData;
    }

    const searchIslamicCentersByCriteria = async (query:string)=>{
        const searchResults =  await mainIslamicCentersDataService.fetchIslamicCentersBySearchCriteria(query);
        if(searchResults){
            return searchResults
        } else{
            return null;
        }
    }

    return {
        getMainIslamicCenter,
        getIslamicCenterReportTypes,
        reportIslamicCenter,
        getMainIslamicCenters,
        getMainIslamicCentersFromUrl,

        getIslamicCenterInstantSearchResults,
        searchIslamicCentersByCriteria
    }
}